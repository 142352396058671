body {
    background: #120E43;
}
.box {
  position: relative;
  color: #fff;
  background: #111;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  margin: 20px 0;
  transition: 0.5s;
    z-index: -1;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
}

.box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  filter: blur(30px);
}

.box:nth-child(2)::before,
.box:nth-child(2)::after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}

.box:nth-child(3)::before,
.box:nth-child(3)::after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}

.box span {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  overflow: hidden;
}

.box span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box .content {
  width: 100%;
  z-index: 10;
  padding: 20px 0;
}

.box ul li {
    font-size: 1.1em;
    font-family: 'Poppins', sans-serif;
    background: #fff;
}
