@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --nav-color: #151048;
  --primary-light-color: #0C98E2;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #151048;
    background-color: var(--nav-color);
    color: #fff;
    height: 70px;
    border-bottom: 1px dotted #fff;
    z-index: 1000;
}

.navContainer {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 5px;
}

.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.navbar .logo {
    cursor: pointer;
}
  
.navbar ul {
    display: -webkit-flex;
    display: flex;
    list-style-type: none;  
}
  
.navbar a {
    text-decoration: none;
    color: #FFF;
    padding: 10px;
    margin: 0;
    transition: 0.2s;
    font-size: 1.2rem;
    cursor: pointer;
}
  
.navbar .link:hover {
    border-bottom: 2px solid #fff;
}
  
.navbar .flex {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

@media(max-width: 900px){
    body {
        overflow-y: visible;
    }
    .navbar {
        height: 110px;
    }
    .navbar .flex {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .navbar ul {
        padding: 15px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 45px;
        margin-bottom: 10px;
    }

    .navbar a {
        font-size: 1rem;
    }
}  
:root {
  --auth-color: #2827cc;
  --auth-dark: #120e43;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainContainer {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: linear-gradient(
    to right,
    #2827cc 0%,
    #2827cc 56.49%,
    #120e43 56.49%,
    #120e43 100%
  );
}

.container {
  width: 900px;
  height: 550px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 15px #2827cc, 2px 2px 25px #120e43;
  box-shadow: 1px 1px 15px var(--auth-color), 2px 2px 25px var(--auth-dark);
}

.container .formImg {
  height: 100%;
  background: #2827cc;
  background: var(--auth-color);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.7s;
}

.formImg h2,
h3 {
  color: #fff;
  text-align: center;
}

.formImg img {
  width: 50%;
}

.container .form {
  height: 100%;
  background: #120e43;
  background: var(--auth-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px 0;
  color: #fff;
  transition: 0.7s;
}

.form p span {
  border: 1px solid #fff;
  background: #fff;
  color: #120e43;
  color: var(--auth-dark);
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 15px;
  cursor: pointer;
}

.form p span:hover {
  box-shadow: 0 0 5px #fff;
}

/* SignUp Container */

.form.signup input[type="text"],
.form.signup input[type="email"],
.form.signup input[type="password"] {
  width: 100%;
  margin-left: 15px;
}

.form.signup .email {
  width: 100%;
  text-align: center;
}

.form.signup input[type="email"],
.form.signup input[type="password"] {
  width: 86%;
}

/* SignUpMode */

.container.signup-mode {
  box-shadow: 1px 1px 15px #2827cc, 2px 2px 25px #120e43;
  box-shadow: 1px 1px 15px var(--auth-color), 2px 2px 25px var(--auth-dark);
}

.container.signup-mode .formImg.signup {
  position: absolute;
  right: 0;
  width: 350px;
}

.container.signup-mode .form.signup {
  position: absolute;
  left: 0;
  width: 550px;
}

/* SuccessMessage */
.successMessage {
  color: #fff;
  background-color: #4dd637;
  padding: 10px;
  border-radius: 20px;
}

/* SuccessMessage */
.errorMessage {
  color: #fff;
  background-color: #e21717;
  padding: 10px;
  border-radius: 20px;
}

/* Errors */
.errorText {
  color: var(--error-color);
  margin-top: 5px;
  font-size: 14px;
}

.errorContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.errorContainer div,
.hEmailContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.errorContainer div span {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-left: 7px;
}

.hEmailContainer div {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-left: 50px;
}

/* Utilities */
.form.signup input[type="text"],
.form.signup input[type="email"],
.form.signup input[type="password"] {
  width: 250px;
  padding: 5px 10px;
  background: none;
  outline: none;
  border: 2px solid #2827cc;
  border: 2px solid var(--auth-color);
  border-radius: 10px;
  font-size: 1.1rem;
  color: #fff;
  transition: 0.5s;
}

.form.signup input[type="email"],
.form.signup input[type="password"]{
  width:90%;
}

.form.signup input::-webkit-input-placeholder {
  color: #c1c1c1;
}

.form.signup input:-ms-input-placeholder {
  color: #c1c1c1;
}

.form.signup input::placeholder {
  color: #c1c1c1;
}

.form.signup input:focus {
  -webkit-transform: scaleX(1.05);
          transform: scaleX(1.05);
}

.myBtn {
  width: 200px;
  padding: 5px;
  background: #2827cc;
  background: var(--auth-color);
  border: 2px solid #2827cc;
  border: 2px solid var(--auth-color);
  border-radius: 5px;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
}

.myBtn:hover {
  box-shadow: 0 0 10px #2827cc;
  box-shadow: 0 0 10px var(--auth-color);
}

.myBtn:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.is-invalid {
  border: 1px solid #e21717;
}

/* Media Queries */
@media (max-width: 900px) {
  .mainContainer {
    position: fixed;
    width: 100%;
  }
  .container {
    /* z-index: 1; */
    width: 350px;
    height: 500px;
    margin-bottom: 100px;
  }

  .container.signup-mode .form.signup {
    left: 0;
    width: 350px;
  }
  .formImg.signup {
    display: none;
  }

  .errorContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .errorContainer div {
    margin: 10px 0;
  }

  .form.signup input[type="text"] {
    width: 100%;
    margin: 0;
  }

  .form.signup input[type="email"],
  .form.signup input[type="password"] {
    width: 70%;
  }
}

:root {
  --auth-color: #2827cc;
  --auth-dark: #120e43;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainContainer {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: linear-gradient(
    to right,
    #2827cc 0%,
    #2827cc 56.49%,
    #120e43 56.49%,
    #120e43 100%
  );
}

.login-container {
  width: 900px;
  height: 550px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 15px #2827cc, 2px 2px 25px #120e43;
  box-shadow: 1px 1px 15px var(--auth-color), 2px 2px 25px var(--auth-dark);
}

.login-container .formImg {
  height: 100%;
  background: #2827cc;
  background: var(--auth-color);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.7s;
}

.login-container .formImg.signin {
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
  transition-delay: 0.7s;
}

.formImg h2,
h3 {
  color: #fff;
  text-align: center;
}

.formImg img {
  width: 50%;
}

.login-container .form {
  height: 100%;
  background: #120e43;
  background: var(--auth-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px 0;
  color: #fff;
  transition: 0.7s;
}

.login-container .form.signin {
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  transition-delay: 0.7s;
}

.form.signin .forgot:hover {
  cursor: pointer;
  text-shadow: 0 0 2px #fff;
}

.form p span {
  border: 1px solid #fff;
  background: #fff;
  color: #120e43;
  color: var(--auth-dark);
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 15px;
  cursor: pointer;
}

.form p span:hover {
  box-shadow: 0 0 5px #fff;
}

/* Errors */
.errorText {
  color: var(--error-color);
  margin-top: 5px;
  font-size: 14px;
}

.errorlogin-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

.errorlogin-container div,
.hEmaillogin-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.errorlogin-container div span {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-left: 7px;
}

.hEmaillogin-container div {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-left: 50px;
}

/* Utilities */
.form.signin input[type="text"],
.form.signin input[type="email"],
.form.signin input[type="password"] {
  width: 250px;
  padding: 5px 10px;
  background: none;
  outline: none;
  border: 2px solid #2827cc;
  border: 2px solid var(--auth-color);
  border-radius: 10px;
  font-size: 1.1rem;
  color: #fff;
  transition: 0.5s;
}

.form.signin input::-webkit-input-placeholder {
  color: #c1c1c1;
}

.form.signin input:-ms-input-placeholder {
  color: #c1c1c1;
}

.form.signin input::placeholder {
  color: #c1c1c1;
}

.form.signin input:focus {
  -webkit-transform: scaleX(1.05);
          transform: scaleX(1.05);
}

.myBtn {
  width: 200px;
  padding: 5px;
  background: #2827cc;
  background: var(--auth-color);
  border: 2px solid #2827cc;
  border: 2px solid var(--auth-color);
  border-radius: 5px;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
  /* transition: .5s; */
}

.myBtn:hover {
  box-shadow: 0 0 10px #2827cc;
  box-shadow: 0 0 10px var(--auth-color);
}

.myBtn:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.is-invalid {
  border: 1px solid #e21717;
}

/* Media Queries */
@media (max-width: 900px) {
  .mainContainer {
    position: fixed;
    width: 100%;
  }
  .login-container {
    width: 350px;
    height: 500px;
    margin-bottom: 100px;
  }
  .formImg.signin {
    display: none;
  }
}

:root {
    --primary: #0C98E2;
    --secondary: #151048;
}

body {
    overflow-x: hidden;
}

.heading {
    text-align: center;
    margin-top: 30px;
    font-size: 1.5rem;
    color: #151048;
    color: var(--secondary);
}

.addBtn {
    text-align: center;
    margin-top: 30px;
}

.user-dashboard .input-group {
    /* z-index: -1; */
}


/* :root {
    --btn-primary: #0C98E2;
    --btn-secondary: #151048;
}

.btn {
    padding: 10px 20px;
    background-color: var(--btn-primary);
    color: #000;
    font-size: 1.5rem;
    border-radius: 30px;
}

.btn:hover {
    background-color: var(--btn-secondary);
    color: #fff;
} */
body {
    background: #120E43;
}
.box {
  position: relative;
  color: #fff;
  background: #111;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-align-items: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px 0;
  transition: 0.5s;
    z-index: -1;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
}

.box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

.box:nth-child(2)::before,
.box:nth-child(2)::after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}

.box:nth-child(3)::before,
.box:nth-child(3)::after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}

.box span {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  overflow: hidden;
}

.box span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box .content {
  width: 100%;
  z-index: 10;
  padding: 20px 0;
}

.box ul li {
    font-size: 1.1em;
    font-family: 'Poppins', sans-serif;
    background: #fff;
}

