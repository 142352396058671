:root {
    --primary: #0C98E2;
    --secondary: #151048;
}

body {
    overflow-x: hidden;
}

.heading {
    text-align: center;
    margin-top: 30px;
    font-size: 1.5rem;
    color: var(--secondary);
}

.addBtn {
    text-align: center;
    margin-top: 30px;
}

.user-dashboard .input-group {
    /* z-index: -1; */
}

